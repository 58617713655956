<template>
  <b-list-group class="aside p-md-3 w-100">
    <div class="aside-top d-flex py-3 px-4 py-md-0 px-md-0 w-100">
      <div class="mr-2"><i class="icon-User"></i></div>
      <div class="account-content">
        <div class="aside-title">{{ $t('帳號') }}</div>
        <div class="aside-account" :title="usernameParser(userData.username)">
          {{ usernameParser(userData.username) }}
        </div>
      </div>
    </div>
    <hr v-if="!$device.isMobile" class="tab-hr" />
    <template v-for="type in asideTypes">
      <div
        v-if="type.show(type.value)"
        :key="type.value"
        class="types px-3 px-md-0"
      >
        <p v-if="$device.isMobile" class="type-title mb-2 mt-4">
          {{ type.title }}
        </p>
        <div class="type-content">
          <template v-for="tab in tabs">
            <b-list-group-item
              v-if="tab.show && tab.type === type.value && !tab.slot"
              :key="tab.value"
              :active="tab.value === activeTab"
              class="tab-item d-flex align-items-center"
              @click="$emit('click-tab', tab.value)"
            >
              <div
                class="d-flex flex-fill justify-content-between align-items-center"
              >
                <div>{{ tab.title }}</div>
                <div
                  v-if="
                    $device.isMobile &&
                    (tab.value === 'bindMobile' || tab.value === 'bindEmail')
                  "
                  class="bind"
                >
                  {{ tab.status }}
                </div>
              </div>
              <i v-if="$device.isMobile" class="icon-Chevron-right"></i>
            </b-list-group-item>
            <slot
              v-if="tab.show && tab.type === type.value && tab.slot"
              :name="tab.slot"
            ></slot>
          </template>
        </div>
      </div>
    </template>
  </b-list-group>
</template>

<script>
import {getInternationalPhoneNumberByUsername} from '@/lib/validation/phoneNumber';
export default {
  props: {
    asideTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    activeTab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      getInternationalPhoneNumberByUsername,
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    isEmailSignUp() {
      return this.$store.getters['user/isEmailSignUp'];
    },
  },
  methods: {
    usernameParser(username) {
      return this.isEmailSignUp
        ? username
        : getInternationalPhoneNumberByUsername(username);
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  background: $bgcontainer;
  width: 100vw;
  @media screen and (min-width: 768px) {
    width: 100%;
    background: white;
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
    border-radius: 10px;
  }
  .aside-top {
    background: white;
    @media screen and (min-width: 768px) {
      background: transparent;
    }
  }
  .types {
    .type-title {
      color: $font-grayscale-1;
      font-size: 12px;
    }
    .type-content {
      background: white;
      border-radius: 3px;
    }
    .tab-item {
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      line-height: 28px;
      padding: 11px 16px;
      border-radius: 0;
      &:not(:last-of-type) {
        border-bottom: 1px solid #dcdfe5;
        @media screen and (min-width: 768px) {
          border-bottom: none;
        }
      }
      @media screen and (min-width: 768px) {
        padding: 14px 16px;
      }
      &.active {
        color: $primary;
        margin-top: 0;
      }
      .bind {
        color: $font-grayscale-2;
      }
      .icon-Chevron-right {
        font-size: 32px;
        color: $font-grayscale-2;
      }
    }
  }
  .tab-hr {
    border-top: 1px solid #dcdfe5;
    width: 100%;
  }
  .aside-top {
    align-items: center;
    @media screen and (min-width: 768px) {
      height: 56px;
    }
    .icon-User {
      font-size: 38px;
      color: $primary;
      margin: 13px;
      @media screen and (min-width: 768px) {
        color: #909399;
      }
    }
    .account-content {
      width: calc((100% - 56px - 8px));
    }
    .aside-title {
      line-height: 22px;
      font-size: 14px;
      color: #606266;
    }
    .aside-account {
      line-height: 28px;
      font-size: 16px;
      color: #303133;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
