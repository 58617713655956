<template>
  <div class="edit w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('設定')"
      :is-title-bold="false"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <loading-wrapper v-if="isPageLoading"></loading-wrapper>
    <div class="edit-container w-100 scroll-bar">
      <b-row class="edit-wrap mx-auto">
        <b-col md="3" class="p-0 px-md-3">
          <SettingAside
            :tabs="tabs"
            :active-tab="activeTab"
            :aside-types="asideTypes"
            class="mr-4"
            @click-tab="changeActiveTab"
          >
            <template v-slot:notification>
              <b-list-group-item class="tab-item d-flex align-items-center">
                <div class="flex-fill">{{ $t('推播通知') }}</div>
                <b-form-checkbox
                  id="remindSettingCheck"
                  v-model="remindSettingCheck"
                  switch
                  size="lg"
                  @change="onSettingCheckChange('remind')"
                ></b-form-checkbox>
              </b-list-group-item>
            </template>
          </SettingAside>
        </b-col>
        <b-col v-if="!$device.isMobile" cols="9" class="main-content">
          <div class="title text-20 font-weight-bold mb-4">
            {{ title }}
          </div>
          <Password v-if="activeTab === 'updatePassword'"></Password>
          <BindEmail v-if="activeTab === 'bindEmail'"></BindEmail>
          <BindMobile v-if="activeTab === 'bindMobile'"></BindMobile>
          <Academy v-if="activeTab === 'editAcademy'"></Academy>
          <RemoveAccount v-if="activeTab === 'removeAccount'"></RemoveAccount>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import SettingAside from '@/components/Base/SettingAside.vue';
import Password from '@/views/Personal/Password.vue';
import BindEmail from '@/views/Personal/BindEmail.vue';
import BindMobile from '@/views/Personal/BindMobile.vue';
import RemoveAccount from '@/views/Personal/RemoveAccount.vue';
import Academy from '@/components/Personal/Academy.vue';
import userService from '@/services/user';

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
    SettingAside,
    Password,
    BindEmail,
    BindMobile,
    RemoveAccount,
    Academy,
  },
  data() {
    return {
      remindSettingCheck: null,
      isPageLoading: false,
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('設定'),
          enable: true,
          router: 'setting',
        },
      ],
      activeTab: this.$device.isMobile ? null : 'updatePassword',
    };
  },
  computed: {
    title() {
      const selectedTab = this.tabs.find((tab) => {
        return this.activeTab === tab.value;
      });
      return selectedTab.title;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    isEmailSignUp() {
      return this.$store.getters['user/isEmailSignUp'];
    },
    isBindedEmail() {
      return this.$store.getters['user/isBindedEmail'];
    },
    isBindedMobile() {
      return this.$store.getters['user/isBindedMobile'];
    },
    asideTypes() {
      return [
        {
          title: this.$t('帳號設定'),
          value: 'account',
          show: (value) =>
            this.tabs.some((tab) => tab.show && tab.type === value),
        },
        {
          title: this.$t('其他'),
          value: 'other',
          show: (value) =>
            this.tabs.some((tab) => tab.show && tab.type === value),
        },
      ];
    },
    tabs() {
      return [
        {
          title: this.$t('更改密碼'),
          value: 'updatePassword',
          type: 'account',
          show: true,
        },
        {
          title: this.isEmailSignUp ? this.$t('綁定手機') : this.$t('綁定信箱'),
          value: this.isEmailSignUp ? 'bindMobile' : 'bindEmail',
          status:
            this.isBindedEmail || this.isBindedMobile
              ? this.$t('已綁定')
              : this.$t('尚未綁定'),
          type: 'account',
          show: true,
        },
        {
          title: this.$t('編輯棋院'),
          value: 'editAcademy',
          type: 'account',
          show: true,
        },
        {
          title: this.$t('刪除帳號'),
          value: 'removeAccount',
          type: 'account',
          show: true,
        },
        {
          title: this.$t('推播通知'),
          value: 'notification',
          type: 'other',
          slot: 'notification',
          show: this.$device.isMobile,
        },
      ];
    },
  },
  mounted() {
    this.initSettingCheck();
  },
  methods: {
    initSettingCheck() {
      this.remindSettingCheck = this.userData.config.isPushNotification;
    },
    onSettingCheckChange(checkbox) {
      switch (checkbox) {
        case 'remind':
          this.$store.state.user.userData.config.isPushNotification =
            !this.$store.state.user.userData.config.isPushNotification;
          return this.updateUser({isPushNotification: this.remindSettingCheck});
      }
    },
    async updateUser(settingChange) {
      await userService.updateUser(settingChange);
      this.$JPush.updateTags();
      this.$store.dispatch('user/loginWithJwt');
      if (!this.$device.isMobile) {
        this.$Message.success(this.$t('更改成功'), 3000);
        this.$emit('close');
      }
    },
    changeActiveTab(value) {
      if (!this.$device.isMobile) {
        this.activeTab = value;
      } else {
        switch (value) {
          case 'updatePassword':
            return this.$router.push({name: 'password'});
          case 'bindEmail':
            return this.$router.push({name: 'bindEmail'});
          case 'bindMobile':
            return this.$router.push({name: 'bindMobile'});
          case 'editAcademy':
            return this.$router.push({name: 'academy'});
          case 'removeAccount':
            return this.$router.push({name: 'removeAccount'});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  overflow: overlay;
}
.edit-container {
  padding: 0px;
  .main-content {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .edit-container {
    padding: 40px 24px 0px;
    height: calc(100vh - 80px);
    overflow: overlay;
    .main-content {
      flex: 1 0 0;
      .title {
        height: 54px;
        line-height: 54px;
        border-bottom: 1px solid #dcdfe5;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .edit-container {
    .edit-wrap {
      max-width: 1140px;
    }
  }
}
</style>
