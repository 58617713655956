<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      v-if="$device.isMobile"
      :title="$t('編輯棋院')"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <div class="academy w-100 p-3 p-md-0">
      <div class="wrap-add-academy p-3 p-md-4 mb-3 mb-md-4">
        <div class="title mb-3 mb-md-2">{{ $t('棋院代號') }}</div>
        <b-form class="academy-form" @submit.prevent="onSubmit">
          <b-form-group label-for="academyCode" class="academy-code-input">
            <b-form-input
              id="academCode"
              ref="academyCode"
              v-model="$v.academyCode.$model"
              name="academyCode"
              aria-describedby="input-nickName-feedback"
              type="text"
              :placeholder="$t('輸入棋院代號')"
              :state="isAcademyCodeValid"
              @input="academyCodeInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-academyCode-feedback"
              :state="isAcademyCodeValid"
              class="mt-0"
            >
              <div v-if="!$v.academyCode.required" class="error">
                *{{ $t('請輸入棋院代號') }}
              </div>
              <div v-else-if="backendError">{{ backendError }}</div>
            </b-form-invalid-feedback>
          </b-form-group>
          <button
            type="submit"
            class="btn btn-primary"
            size="md"
            @click="onSubmit"
          >
            {{ $t('儲存') }}
          </button>
        </b-form>
      </div>
      <div class="academy-list p-3 p-md-4">
        <div class="title mb-3">{{ $t('我所屬的棋院') }}</div>
        <div v-if="academys.length <= 0" class="empty text-18 text-center">
          {{ $t('尚無所屬棋院') }}
        </div>
        <template v-for="academy in academys">
          <div :key="academy.code" class="academy-item d-flex">
            <div class="badge text-white mr-2">
              <i class="icon-Flag"></i>
            </div>
            <div class="flex-fill">{{ academy.name }}</div>
            <div class="btn-wrap-remove">
              <b-button
                size="sm"
                class="mr-1 p-md-0"
                variant="link"
                @click="removeAcademy(academy)"
              >
                {{ $t('移除') }}
              </b-button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <modal-message-box
      v-if="isModalRemoveAcademyShow"
      :result-content="resultContent"
      :buttons="messageBoxButtons"
      :has-btn-close="true"
      @on-item-click="modalRemoveAcademyClick"
      @close="closeModalRemoveAcademy"
    >
    </modal-message-box>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';

import academyService from '@/services/academy';
import userService from '@/services/user';

import BackEvent from '@/lib/base/backEvent.js';
import {removeLocalData} from '@/lib/base/localData';
import {required} from 'vuelidate/lib/validators';

export default {
  components: {
    BaseHeader,
    ModalMessageBox,
  },
  props: {},
  data() {
    return {
      academyCode: '',
      isAcademyCodeValid: null,
      isModalRemoveAcademyShow: false,
      messageBoxButtons: [
        {
          variant: 'secondary',
          value: 'cnacle',
          content: '取消',
        },
        {
          variant: 'primary',
          value: 'confirm',
          content: '確定',
        },
      ],
      resultContent: '',
      selectedAcademy: null,
    };
  },
  validations: {
    academyCode: {required},
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    academys() {
      return this.userData.subAcademies;
    },
  },
  methods: {
    academyCodeInputHandler() {
      this.isAcademyCodeValid = this.academyCode ? null : false;
      this.backendError = '';
    },
    onSubmit() {
      this.$v.$touch();
      this.isAcademyCodeValid = null;
      if (this.$v.$invalid) {
        this.isAcademyCodeValid = false;
        this.$refs.academyCode.focus();
      } else {
        this.addSubAcademyUserByCode();
      }
    },
    async addSubAcademyUserByCode() {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      try {
        const {name} = await academyService.addSubAcademyUserByCode(
          this.academyCode
        );
        const successText =
          this.$t('輸入成功，您已與') + name + this.$t('棋院綁定');
        this.academyCode = '';
        removeLocalData('user');
        userService.getUserData?.cache.clear();
        await this.$store.dispatch('user/loginWithJwt');
        this.$Message.success(successText);
      } catch (error) {
        if (error.response.data.message === '查無分院') {
          this.$Message.error(this.$t('此棋院代號不存在'));
        } else if (error.response.data.message === '已加入該分院') {
          this.$Message.error(this.$t('此棋院代號已綁定'));
        } else {
          this.$Message.error(this.$t(error.response.data.message));
        }
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    closeModalRemoveAcademy() {
      BackEvent.popEvent();
      this.isModalRemoveAcademyShow = false;
    },
    modalRemoveAcademyClick(value) {
      BackEvent.popEvent();
      this.isModalRemoveAcademyShow = false;
      if (value === 'confirm') {
        this.deleteSubAcademyUserByCode(this.selectedAcademy);
      }
    },
    removeAcademy(academy) {
      this.isModalRemoveAcademyShow = true;
      this.resultContent = this.$t('確定要移除') + ' ' + academy.name;
      this.selectedAcademy = academy;
    },
    async deleteSubAcademyUserByCode(academy) {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      try {
        await academyService.deleteSubAcademyUserByCode(
          this.userData.username,
          academy.subAcademyId
        );
        removeLocalData('user');
        userService.getUserData?.cache.clear();
        await this.$store.dispatch('user/loginWithJwt');
        const successText = this.$t('成功移除所屬棋院') + ' - ' + academy.name;
        this.$Message.success(successText);
      } catch (error) {
        this.$Message.error(error.response.data.message);
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-add-academy,
.academy-list {
  background: white;
  box-shadow: 0px 2px 7px 0px #c7c7c74d;
  border-radius: 10px;
  .title {
    background: $bgsection;
    color: $primary;
    font-weight: 700;
    padding: 5px 16px;
    border-radius: 10px;
    font-size: 18px;
    @media screen and (min-width: 768px) {
      background: transparent;
      color: black;
      font-weight: 400;
      padding: 0;
      font-size: 16px;
    }
  }
}

.wrap-add-academy {
  .academy-form {
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }
  .error {
    margin-top: 4px;
    height: 16px;
    color: $negative;
    font-size: 12px;
    text-align: start;
  }
  .form-control {
    font-size: 14px;
  }
  .btn-primary {
    width: 152px;
    @media screen and (min-width: 768px) {
      width: 240px;
    }
  }
}

.academy-list {
  .empty {
    color: $font-grayscale-1;
  }
  .academy-item {
    position: relative;
    height: 60px;
    width: 100%;
    padding: 16px 10px;
    align-items: center;
    background: $bgcontainer;
    .badge {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 12px;
      background: $grayscale-30;
      font-size: 20px;
      padding: 2px 0px 2px;
    }
    .btn-wrap-remove {
      width: 60px;
      @media screen and (min-width: 768px) {
        width: 140px;
      }
    }
  }
}
</style>
