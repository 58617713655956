<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      v-if="$device.isMobile"
      :title="$t('綁定信箱')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <div class="email-container w-100 p-3 p-md-4">
      <template v-if="isBindedEmail">
        <BindedContent :binded-data="userData.email"></BindedContent>
      </template>
      <template v-else>
        <b-form class="email-form" @submit.prevent="onSubmit">
          <b-form-group
            label-for="email"
            :label="$device.isMobile ? null : '信箱'"
            class="email-input"
          >
            <b-form-input
              id="email"
              ref="email"
              v-model="$v.formData.email.$model"
              name="email"
              aria-describedby="input-email-feedback"
              type="email"
              :placeholder="$t('請輸入信箱')"
              :state="formData.isEmailValid"
              @input="emailInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-email-feedback"
              :state="formData.isEmailValid"
              class="mt-0"
            >
              <div v-if="!$v.formData.email.required" class="error">
                {{ $t('*請輸入信箱') }}
              </div>
              <div
                v-else-if="backendError === 'User already exist!'"
                class="error"
              >
                {{ $t('該使用者已註冊或已綁定') }}
              </div>
              <div
                v-else-if="backendError === 'Cannot send message in limit time'"
                class="error"
              >
                {{ $t('操作過於頻繁，請稍後再嘗試') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
        <div>
          <b-button variant="primary" size="md" @click="onSubmit">
            {{ $t('發送信件') }}
          </b-button>
        </div>
      </template>
    </div>
    <BindNotice class="mt-md-3 mx-3 mx-md-0"></BindNotice>
    <modal-confirm
      v-model="isEmailSendModalShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      @confirm="isEmailSendModalShow = false"
    >
      {{ $t('信件已發送') }}<br />
      {{ $t('請至信箱收信') }}
    </modal-confirm>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import BindNotice from '@/components/Personal/BindNotice.vue';
import BindedContent from '@/components/Personal/BindedContent.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import userService from '@/services/user';

import {required} from 'vuelidate/lib/validators';
import socket from '@/lib/socket/socket.js';
import {removeLocalData} from '@/lib/base/localData';
import AppEvent from '@/lib/base/appEvent.js';

export default {
  components: {
    BaseHeader,
    BindNotice,
    BindedContent,
    ModalConfirm,
  },
  data() {
    return {
      isEmailSendModalShow: false,
      formData: {
        email: '',
        isEmailValid: null,
      },
      backendError: '',
    };
  },
  validations: {
    formData: {
      email: {required},
    },
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    isBindedEmail() {
      return this.$store.getters['user/isBindedEmail'];
    },
  },
  created() {
    // socket事件針對web
    socket.clearEvents([socket.ResponseEventEnum.UPDATE_SUCCESSFULLY]);
    socket.once(
      socket.ResponseEventEnum.UPDATE_SUCCESSFULLY,
      async (result) => {
        const email = result?.data?.email;
        if (email) this.resetUserData();
      }
    );
    // app事件針對手機app
    AppEvent.on(AppEvent.eventNameEnum.APP_STATE_CHANGE, async () => {
      this.resetUserData();
    });
  },
  beforeDestroy() {
    socket.clearEvents([socket.ResponseEventEnum.UPDATE_SUCCESSFULLY]);
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      this.formData.isEmailValid = null;

      if (this.$v.$invalid) {
        this.formData.isEmailValid = this.validateEmail() ? null : false;

        this.focusInput();
      } else {
        await this.sendMobileOtp();
      }
    },
    emailInputHandler() {
      this.backendError = '';
      this.formData.isEmailValid = null;
    },
    validateEmail() {
      const {$error} = this.$v.formData.email;
      return !$error;
    },
    focusInput() {
      if (!this.validateEmail()) {
        this.$refs.email.focus();
      } else {
        return false;
      }
    },
    async resetUserData() {
      removeLocalData('user');
      userService.getUserData?.cache.clear();
      await this.$store.dispatch('user/loginWithJwt');
    },
    async sendMobileOtp() {
      this.$store.commit('env/setIsLoading', true);
      const url = `${window.location.origin}/#/personal/setting/bindStatus`;
      const data = {
        email: this.formData.email,
        redirect: url,
        type: 'EMAIL_SET',
      };
      try {
        await userService.bindAccountSendOtp(data);
        this.$store.commit('env/setIsLoading', false);
        this.isEmailSendModalShow = true;
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.backendError = error.response.data.message;
        if (this.backendError !== '') {
          this.formData.isEmailValid = false;
          this.$Message.error(this.$t('信件發送失敗'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-container {
  @media screen and (min-width: 768px) {
    background: white;
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
    border-radius: 10px;
  }
  .title {
    @media screen and (min-width: 768px) {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: $font-grayscale-1;
      padding: 16px 6px 16px 16px;
    }
  }
  .btn-primary {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 240px;
    }
  }
}
.error {
  margin-top: 4px;
  height: 16px;
  color: $negative;
  font-size: 12px;
  text-align: start;
}
.success-wrapper {
  height: calc(100vh - 56px);
  i {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $positive;
    color: #ffffff;
    font-size: 66px;
  }
  span {
    color: $font-grayscale-2;
  }
}
</style>
