import {callLambda} from '@/lib/api/lambda';

/**
 * 將自己加入棋院(分院)
 * @param {String} code 棋院代號
 * @returns {Array} Array
 */
function addSubAcademyUserByCode(code) {
  return callLambda({
    category: 'academy',
    method: 'post',
    apiName: 'addSubAcademyUserByCode',
    data: {code},
  }).then((res) => {
    return res.data;
  });
}

/**
 * 將自己移除棋院(分院)
 * @param {String} username 帳號
 * @param {String} subAcademyId 棋院id
 * @returns {Array} Array
 */
function deleteSubAcademyUserByCode(username, subAcademyId) {
  return callLambda({
    category: 'subAcademy',
    apiName: 'user',
    method: 'DELETE',
    data: {
      username,
      subAcademyId,
    },
  }).then((res) => {
    return res.data;
  });
}

export default {
  addSubAcademyUserByCode,
  deleteSubAcademyUserByCode,
};
